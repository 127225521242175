export default [
  {
    path: '/demo-mio/pagina-vuota',
    name: 'demo-mio-pagina-vuota',
    component: () => import('@/views/page-demo-mio/PaginaVuota.vue'),
    meta: {
      pageTitle: 'Pagina Vuota',
      breadcrumb: [
        {
          text: 'Sup-Directory',
        },
        {
          text: 'Pagina Vuota',
          active: true,
        },
      ],
    },
  },
  {
    path: '/demo-mio/tabella-test1',
    name: 'demo-mio-tabella-test1',
    component: () => import('@/views/page-demo-mio/TabellaTest1.vue'),
    meta: {
      pageTitle: 'Tabella test 1',
      breadcrumb: [
        {
          text: 'Sup-Directory',
        },
        {
          text: 'Tabella test 1',
          active: true,
        },
      ],
    },
  },
  {
    path: '/demo-mio/formnuovo-test1',
    name: 'demo-mio-formnuovo-test1',
    component: () => import('@/views/page-demo-mio/Formnuovo.vue'),
    meta: {
      pageTitle: 'Nuovo',
      breadcrumb: [
        {
          text: 'Sup-Directory',
        },
        {
          text: 'Tabella test 1',
          to: 'tabella-test1',
        },
        {
          text: 'Form per la creazione di una riga nel db',
          active: true,
        },
      ],
    },
  },
  {
    path: '/demo-mio/formedit-test1/:id',
    name: 'demo-mio-formedit-test1',
    component: () => import('@/views/page-demo-mio/Formedit.vue'),
    meta: {
      pageTitle: 'Modifica',
      breadcrumb: [
        {
          text: 'Sup-Directory',
        },
        {
          text: 'Tabella test 1',
          to: 'tabella-test1',
        },
        {
          text: 'Form modifica di una riga nel db',
          active: true,
        },
      ],
    },
  },
  {
    path: '/page-layouts/layout-blank',
    name: 'page-layout-layout-blank',
    component: () => import('@/views/ui/page-layouts/LayoutBlank.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Layout Blank',
    },
  },
]
