export default [
  {
    path: '/catalogo/articoli',
    name: 'catalogo-articoli',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoli.vue'),
    meta: {
      pageTitle: 'I tuoi Prodotti',
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/articolidisponibili',
    name: 'catalogo-articoli-tutti',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoliTutti.vue'),
    meta: {
      pageTitle: 'Tutti i Prodotti',
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/carrello',
    name: 'catalogo-carrello',
    component: () => import('@/views/rubisco-catalogo-prodotti/carrello.vue'),
    meta: {
      pageTitle: 'Articoli nell\'Ordine',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'I tuoi Prodotti',
          to: 'articoli',
        },
        {
          text: 'Carrello',
          active: true,
        },
      ],
    },
  },
  {
    path: '/catalogo/Contatti',
    name: 'catalogo-contatti',
    component: () => import('@/views/ui/page-layouts/LayoutBlank.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Contatti',
      action: 'read',
      resource: 'ACL',
    },
  },
]
