export default [
  {
    path: '/azienda/anagrafica',
    name: 'azienda-anagrafica',
    component: () => import('@/views/rubisco-azienda/anagrafica.vue'),
    meta: {
      pageTitle: 'Anagrafica Azienda',
      action: 'read',
      resource: 'ACL',
    },
  },
  {
    path: '/azienda/ordini',
    name: 'azienda-ordini',
    component: () => import('@/views/rubisco-azienda/listaOrdini.vue'),
    meta: {
      pageTitle: 'Ordini Effettuati',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: 'anagrafica',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
  {
    path: '/azienda/ordineinfo/:id/:numero_ordine',
    name: 'azienda-ordine-infostronzo',
    component: () => import('@/views/rubisco-azienda/ordineInfo.vue'),
    meta: {
      pageTitle: 'Riepilogo Ordine',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: '../../anagrafica',
          action: 'read',
          resource: 'ACL',
        },
        {
          text: 'Ordini',
          to: '../../ordini',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
  {
    path: '/azienda/leads',
    name: 'azienda-leads',
    component: () => import('@/views/rubisco-azienda/listaLead.vue'),
    meta: {
      pageTitle: 'Richieste',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: '../anagrafica',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
  {
    path: '/azienda/leads/in-attesa',
    name: 'azienda-leads-in-attesa',
    component: () => import('@/views/rubisco-azienda/listaLead-in-attesa.vue'),
    meta: {
      pageTitle: 'Richieste',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: '../anagrafica',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
  {
    path: '/azienda/leads/conclusi',
    name: 'azienda-leads-conslusi',
    component: () => import('@/views/rubisco-azienda/listaLead-concluse.vue'),
    meta: {
      pageTitle: 'Richieste',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: '../anagrafica',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
  {
    path: '/azienda/leadinfo/:id_lead',
    name: 'azienda-lead-dettaglio',
    component: () => import('@/views/rubisco-azienda/leadInfo.vue'),
    meta: {
      pageTitle: 'Riepilogo Richiesta',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: '../../anagrafica',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
  {
    path: '/azienda/lead/nuovo',
    name: 'azienda-leads-nuovo',
    component: () => import('@/views/rubisco-azienda/leadNuovo.vue'),
    meta: {
      pageTitle: 'Nuova Richiesta',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Azienda',
          to: '../anagrafica',
          action: 'read',
          resource: 'ACL',
        },
        {
          text: 'Richieste',
          to: '../leads/in-attesa',
          action: 'read',
          resource: 'ACL',
        },
      ],
    },
  },
]
